import React from 'react'
/* import googlePlayBadge from '../assets/buttons/pt_badge_web_generic.png' */
import logo from '../assets/images/logo.jpg'
import './Footer.css'

export default () => (
  <footer className="footer">
    <div className="container flex">
      <div className="flex footer-main">
        <div className="flex footer-slogan">
          <img className="logo" src={logo} width="164px" height="50px" alt="Logo do Situs Arqueologia" />
          <span style={{ marginTop: 16 }}>
            <a href="mailto:contato@situsarqueologia.com.br">
              contato@situsarqueologia.com.br
            </a>
          </span>
          {/* <p className="description">
            Pellentesque semper nec mi eu hendrerit. Phasellus a tempus dolor,
            id bibendum erat. Maecenas dictum venenatis maximus. Pellentesque
            semper id massa nec maximus.
          </p> */}
        </div>
        {/* <div className="flex footer-download">
          <h2>
            Baixe agora o aplicativo
          </h2>
          <a
            href="https://play.google.com/store/apps/details?id=br.com.situsarqueologia"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Disponível no Google Play"
              src={googlePlayBadge}
              width="166"
              height="50"
            />
          </a>
        </div> */}
      </div>
      <hr />
      <div className="flex disclaimer">
        <span>
          &copy; 2020 Situs Arqueologia. Todos os direitos reservados.
        </span>
        <span>
          <a
            href="https://situsarqueologia.com.br/termos"
            target="_blank"
            rel="noopener noreferrer"
          >
            Termos de Uso
          </a>
          <span style={{ marginLeft: 10, marginRight: 10 }}>|</span>
          <a
            href="https://situsarqueologia.com.br/privacidade"
            target="_blank"
            rel="noopener noreferrer"
          >
            Política de Privacidade
          </a>
        </span>
      </div>
    </div>
  </footer>
)
