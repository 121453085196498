import React from 'react'
import './Nav.css'

export default () => (
  <nav className="nav">
    {/* <ul>
      <li><a className="nav-link" href="/">Início</a></li>
      <li><a className="nav-link" href="#overview">Quem somos</a></li>
      <li><a className="nav-link" href="#solutions">Soluções</a></li>
      <li><a className="nav-link" href="/">Blog</a></li>
      <li><a className="nav-link" href="/">Contato</a></li>
    </ul> */}
    <a
      className="btn-download"
      href="https://play.google.com/store/apps/details?id=br.com.situsarqueologia&referrer=utm_source%3Dsite%26utm_medium%3Dbotao_nav"
      target="_blank"
      rel="noopener noreferrer"
    >
      Download
    </a>
  </nav>
)
