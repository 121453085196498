import React from 'react'
import Logo from './Logo'
import Nav from './Nav'
import './Header.css'

export default () => (
  <header className="header">
    <div className="container flex header-main">
      <div className="logo">
        <Logo />
      </div>
      <div className="nav">
        <Nav />
      </div>
    </div>
  </header>
)
