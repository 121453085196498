import React from 'react'
import googlePlayBadge from '../assets/buttons/pt_badge_web_generic.png'
import mockup from '../assets/images/banner-mockup.png'
import './Banner.css'

export default () => (
  <section className="banner">
    <div className="container flex banner-main">
      <div className="presentation">
        <div className="title">
          <h1>
            Situs Arqueologia
          </h1>
        </div>
        <div className="description">
          <p>
            Mapas, fotos e fichas de campo em um único ambiente informatizado,
            seguro e disponível com ou sem sinal de internet, na palma da sua mão.
          </p>
        </div>
        <div className="actions">
          <a
            href="https://play.google.com/store/apps/details?id=br.com.situsarqueologia&referrer=utm_source%3Dsite%26utm_medium%3Dbotao_banner_header"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Disponível no Google Play"
              src={googlePlayBadge}
              width="166"
              height="50"
            />
          </a>
        </div>
      </div>
      <div className="mockup">
        <img
          src={mockup}
          alt="Ilustração do app em smartphone e tablet"
        />
      </div>
    </div>
  </section>
)
