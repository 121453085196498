import React from 'react'
import googlePlayBadge from '../assets/buttons/pt_badge_web_generic.png'
import Banner from '../components/Banner'
import Footer from '../components/Footer'
import Header from '../components/Header'
import './Home.css'

export default () => (
  <>
    <Header />
    <Banner />
    <section className="content">
      <section className="overview flex container" id="overview">
        <h2>
          Como funciona
        </h2>
        <hr />
        <p>
          O Situs é um aplicativo para gerenciamento de projetos arqueológicos.
          Com ele, você e sua equipe compartilham a mesma base de dados. Em campo,
          facilite o trabalho com as funcionalidades integradas, como mapa, ficha
          informatizada e a possibilidade de inclusão de fotos.
        </p>
        <div className="flex highlights">
          <div className="highlights-item">
            <h3>1. Organize as atividades</h3>
            <p>
              Do escritório, cadastre o projeto e as coordenadas dos pontos
              que serão vistoriados pela equipe de campo.
            </p>
          </div>
          <div className="highlights-item">
            <h3>2. Realize campos</h3>
            <p>
              No app, utilize o mapa disponível para chegar até os locais de interesse
              e preencher as fichas de campo.
            </p>
          </div>
          <div className="highlights-item">
            <h3>3. Entregue os produtos</h3>
            <p>
              Acompanhe em tempo real o andamento do projeto. A qualquer momento,
              exporte as informações no formato desejado.
            </p>
          </div>
        </div>
      </section>
      <section className="video" id="video">
        <div className="container video-main">
          <div className="flex">
            <h2>
              Saiba mais
            </h2>
            <hr />
          </div>
          <iframe
            className="video-iframe"
            title="video"
            width="100%"
            height="100%"
            src="https://www.youtube-nocookie.com/embed/QjLJbcfam9I?controls=0"
            frameBorder="0"
            allow="encrypted-media;"
            allowFullScreen
          />
        </div>
      </section>
      <section className="container flex advantages">
        <h2>
          Vantagens
        </h2>
        <hr />
        <p>
          O Situs nasceu da necessidade de aprimorar a padronização dos dados
          gerados em campo, de forma a conferir maior agilidade na condução das atividades.
          Além disso, acreditamos que a fidelidade dos dados e a segurança no armazenamento
          das informações são fatores cruciais para o sucesso dos projetos.
        </p>
        <div className="flex advantages-items">
          <div className="flex advantages-item">
            <div className="advantages-item-icon">
              <span>
                <i className="icon-check" />
              </span>
            </div>
            <div>
              <h3>
                Padronização
              </h3>
              <p>
                Pelo app, utilize formulários prontos para o preechimento de fichas de
                campo, pensados por arqueólogos experientes. Em poucos passos, inclua
                registros para sondagens e ocorrências arqueológicas com fotos e coordenadas
                geográficas.
              </p>
            </div>
          </div>
          <div className="flex advantages-item">
            <div className="advantages-item-icon">
              <span>
                <i className="icon-check" />
              </span>
            </div>
            <div>
              <h3>
                Agilidade
              </h3>
              <p>
                Conceda acesso para o projeto aos seus colegas de equipe e todos
                compartilharão a mesma base de dados. Acompanhe o andamento das atividades
                em tempo real. A qualquer momento, gere planilhas e relatórios com os dados
                coletados.
              </p>
            </div>
          </div>
          <div className="flex advantages-item">
            <div className="advantages-item-icon">
              <span>
                <i className="icon-check" />
              </span>
            </div>
            <div>
              <h3>
                Fidelidade
              </h3>
              <p>
                Ative o GPS do seu dispositivo móvel e o Situs armazenará as coordenadas
                geográficas dos locais vistoriados. Ao utilizar o app, você se certifica
                que as fotos anexas às fichas foram tiradas no local correto e
                pelo dispositivo do profissional em campo.
              </p>
            </div>
          </div>
          <div className="flex advantages-item">
            <div className="advantages-item-icon">
              <span>
                <i className="icon-check" />
              </span>
            </div>
            <div>
              <h3>
                Segurança
              </h3>
              <p>
                Ao criar um projeto no Situs você garante que os dados e informações
                gerados ao longo das atividades estarão armazenados em um banco de dados
                seguro e disponível sempre que você precisar e aonde quer que esteja.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="subscribe" id="subscribe">
        <div className="container flex subscribe-main">
          <div>
            <h1>
              Comece agora mesmo
            </h1>
            <p>
              Faça o download do aplicativo e tenha acesso imediato
              à todas as funcionalidades
            </p>
          </div>
          <a
            href="https://play.google.com/store/apps/details?id=br.com.situsarqueologia&referrer=utm_source%3Dsite%26utm_medium%3Dbotao_banner_footer"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="Disponível no Google Play"
              src={googlePlayBadge}
              width="166"
              height="50"
              className="flex"
            />
          </a>
        </div>
      </section>
    </section>
    <Footer />
  </>
)
